import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h1>About</h1>
    <div>
      Icons made by{" "}
      <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
        Freepik
      </a>{" "}
      from{" "}
      <a href="https://www.flaticon.com/" title="Flaticon">
        www.flaticon.com
      </a>
    </div>
  </Layout>
)

export default AboutPage
